import Vue from 'vue';
import Notifications from 'vue-notification';
import categoryCarousel from '../components/CategoryCarousel.vue';

if (process.env.VUE_APP_DEV_TOOLS) {
  Vue.config.devtools = true;
}
Vue.use(Notifications);

const args = document.getElementById('vcategoryCarousel').getAttribute('data-args');

new Vue({
  render: (h) => h(categoryCarousel, { props: { args } })
}).$mount('#vcategoryCarousel');
