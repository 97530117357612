<template>
  <div class="category_carousel"
    v-if="categoryCarousels && categoryCarousels.length > 0">
    <div class="category_carousel_header">
      <div class="category_carousel_header__box">
        <div class="category_carousel_header__title">
          {{ groupTitle }}
        </div>
      </div>
      <div class="category_carousel_actions">
        <a class="category_carousel_actions__cta wc-button wc-button--link wc-button--small"
        v-if="seeAllLink" :href="seeAllLink">
          <span class="label">See all categories</span>
        </a>
        <div class="category_carousel_actions__nav"
        v-if="pageCount > 1">
          <button class="wc-button wc-button--secondary wc-button--small wc-button--small__icon"
            @click="goToSlide(activeSlide - 1)" :disabled="activeSlide === 0">
            <span class="label"><i class="fa-solid fa-chevron-left"></i></span>
          </button>
          <button class="wc-button wc-button--secondary wc-button--small wc-button--small__icon"
            @click="goToSlide(activeSlide + 1)" :disabled="activeSlide === pageCount - 1">
            <span class="label"><i class="fa-solid fa-chevron-right"></i></span>
          </button>
        </div>
      </div>
    </div>
    <div class="category_carousel_body">
      <Carousel :ref="carouselRef" :navigationEnabled="false" :paginationEnabled="false"
        @pageChange="updateActiveSlide"
        :scrollPerPage="true" :perPageCustom="[[480, 2], [996, 4], [1320, 6]]">
        <Slide v-for="(item, index) in categoryCarousels" :key="index">
            <div class="categorycard">
                <div class="categorycard__imagebox">
                    <a :href="item.link" class="categorycard__link">
                        <img :src="item.image" :alt="item.title" />
                    </a>
                </div>
                <div class="categorycard__label">
                {{ item.title }}
                </div>
            </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'CategoryCarousel',
  props: {
    args: String,
  },
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      activeSlide: 0,
      pageCount: 0,
    };
  },
  computed: {
    arguments() {
      return JSON.parse(this.args);
    },
    groupTitle() {
      return this.arguments.group_title;
    },
    categoryCarousels() {
      return this.arguments.category_carousels;
    },
    seeAllLink() {
      return this.arguments.link;
    },
    carouselRef() {
      // Generate a unique ref for each carousel
      const uid = Math.random().toString(36).substr(2, 9);
      return `carousel_${uid}`;
    }
  },
  methods: {
    goToSlide(index) {
      this.activeSlide = index;
      this.pageCount = this.$refs[this.carouselRef].pageCount;
      this.$refs[this.carouselRef].goToPage(index);
    },
    switchCarousel(index) {
      this.activeSlide = index;
    },
    updateActiveSlide(index) {
      this.activeSlide = index;
    }
  },
  mounted() {
    this.pageCount = this.$refs[this.carouselRef].pageCount;
  }
};
</script>
